<template>
  <div class="success">
    <div class="boxcard">
      <div class="cptit">数字化水平提升服务选择</div>
      <div class="content">
        <el-form ref="form" :model="form" label-width="115px">
          <el-form-item label="数字化咨询">
            <el-checkbox-group v-model="form.szhzx">
              <el-checkbox v-for="item in szhzx" :key="item.id" :label="item" :value="item.score" name="szhzx">
                {{ item.title }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item>
            <el-checkbox-group v-model="form.others" @change="otherChange">
              <el-checkbox v-for="item in others" :key="item.id" :label="item" :value="item.score" name="szhzx">
                {{ item.title }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="calculateElevate">测算提升效果</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mobile: '',
      form: {
        szhzx: [],
        others: [],
        // changJings: [
        //   {secondIndex: "产品设计*", one: false, two: false, three: false, four: false, isImportant: true},
        //   {secondIndex: "工艺设计", one: false, two: false, three: false, four: false, isImportant: false},
        //   {secondIndex: "营销管理*", one: false, two: false, three: false, four: false, isImportant: true},
        //   {secondIndex: "售后服务", one: false, two: false, three: false, four: false, isImportant: false},
        //   {secondIndex: "计划排程", one: false, two: false, three: false, four: false, isImportant: false},
        //   {secondIndex: "生产管控*", one: false, two: false, three: false, four: false, isImportant: true},
        //   {secondIndex: "质量管理*", one: false, two: false, three: false, four: false, isImportant: true},
        //   {secondIndex: "设备管理*", one: false, two: false, three: false, four: false, isImportant: true},
        //   {secondIndex: "安全生产*", one: false, two: false, three: false, four: false, isImportant: true},
        //   {secondIndex: "能耗管理*", one: false, two: false, three: false, four: false, isImportant: true},
        //   {secondIndex: "采购管理*", one: false, two: true, three: false, four: false, isImportant: true},
        //   {secondIndex: "仓储物流*", one: false, two: false, three: false, four: false, isImportant: true},
        //   {secondIndex: "财务管理*", one: false, two: false, three: false, four: false, isImportant: true},
        //   {secondIndex: "人力资源", one: false, two: false, three: false, four: false, isImportant: false},
        //   {secondIndex: "协同办公", one: false, two: false, three: false, four: false, isImportant: false},
        //   {secondIndex: "决策支持", one: false, two: false, three: false, four: false, isImportant: false}
        // ],
      },
      szhzx: [
        {"id": "48", "title": "网络建设", "score": "2"},
        {"id": "53", "title": "网络安全", "score": "2.5"},
        {"id": "54", "title": "数据安全", "score": "2.5"},
        {"id": "56", "title": "管理机制", "score": "5.63"},
      ],
      others: [
        {"id": "1", "title": "聚通设备管理云平台", "score": "6"},
        {"id": "2", "title": "聚通CRM", "score": "1.54"},
        {"id": "3", "title": "聚通精益生产管理", "score": "2.31"},
        {"id": "4", "title": "聚通安全生产云平台", "score": "0.77"},
        {"id": "5", "title": "聚通能源管理平台", "score": "0.77"},
        {"id": "6", "title": "聚通云ERP", "score": "2.31"},
        {"id": "7", "title": "聚通数字化企业运营看板", "score": "6"},
        {"id": "8", "title": "园趣云办公", "score": "0.77"},
      ],
    }
  },
  mounted() {
    const mobile = localStorage.getItem('mobile');
    this.mobile = mobile;
  },
  methods: {
    calculateElevate() {
      // 单选“聚通精益生产管理”达到二级，选择“精益生产+设备管理”达到三级
      // const condition1 = "聚通设备管理云平台";
      // const condition2 = "聚通精益生产管理";
      // let is1 = false;
      // let is2 = false;
      // this.form.others.forEach((item) => {
      //   if (item.title.includes(condition1)) {
      //     is1 = true;
      //   }
      //   if (item.title.includes(condition2)) {
      //     is2 = true;
      //   }
      // })
      // if (is1 && is2) {
      //   this.form.changJings[4].three = true;
      //   this.form.changJings[4].two = false;
      // }
      console.log("form", this.form);
      this.$axios.post('/zhenduan/api/yexam/exam/elevate', {
        mobile: this.mobile,
        szhzx: this.form.szhzx,
        others: this.form.others
      }).then(res => {
        console.log(res);
      }).catch((err) => {
        console.log(err)
      })
    },
    otherChange(value){
      this.form.others = value;
    }
  }
}
</script>
<style scoped lang="scss">
.success {
  height: 100vh;
  background: url("../../../src/assets/successbg.jpg") no-repeat;
  background-size: 100% 100%;
  position: relative;

  .boxcard {
    width: 35%;
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.8);
    padding: 150px 50px 50px;
    box-shadow: 0 0 20px #477dde inset;

    .cptit {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background: rgba(22, 39, 241, 0.5);
      font-size: 20px;
      color: #fff;
      font-weight: bold;
      padding: 28px 12px 0;
      width: 80%;
      text-align: center;
    }

    .cptit::after {
      content: '';
      width: 100%;
      height: 1px;
      border-left: 110px solid transparent;
      border-right: 110px solid transparent;
      border-top: 30px solid rgba(22, 39, 241, 0.5);;
      position: absolute;
      bottom: -30px;
      left: 0;
    }

    .content {
      text-align: center;

      .el-button {
        font-size: 20px;
        padding: 15px 40px;
        cursor: pointer;
        margin-top: 20px;
      }

      .title {
        font-size: 16px;
        letter-spacing: 1px;
        font-weight: 500;
      }
    }
  }

  .boxcard::before {
    content: '';
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    border-radius: 15px;
    border: 2px solid #1627f1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }

  .boxcard::after {
    content: '';
    width: calc(100% - 50px);
    height: calc(100% - 50px);
    border-radius: 15px;
    border: 1px solid #345bf0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }
}

@media only screen and (max-width: 768px) {
  .boxcard {
    width: 100%;
  }
  .boxcard .cptit {
    padding: 30px 30px 0;
    font-size: 24px;
    width: 50%;
    text-align: center;
  }
  .boxcard .cptit::after {
    border-left: 90px solid transparent;
    border-right: 90px solid transparent;
  }
  .content {
    ::v-deep .el-col-8 {
      width: 100%;
      margin: 0 0 10px;
    }

    .score-section p {
      padding: 0;
    }

    .el-button {
      font-size: 16px;
      padding: 10px 20px;
    }
  }

}
</style>
