<template>
  <div class="companyZD">
    <p>诊断大屏页</p>
    {{ daPingData }}
  </div>
</template>
<script>
  export default {
    name: "CompanyZD",
    data() {
      return {
        userName: '',
        daPingData: []
      }
    },
    mounted() {
      const userName = this.$route.query.userName;
      if (userName){
        this.userName = userName;
        this.getDaPingData();
      }
    },
    methods: {
      getDaPingData(){
        this.$axios.get('/zhenduan/api/yexam/exam/company_schedule_byUserName', {
          params:{userName: this.username},
        }).then(res =>{
          this.daPingData = res.data;
        })
      }
    }
  }
</script>

<style scoped lang="scss">

</style>
