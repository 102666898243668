<template>
	<div class="downline">
    <div class="banner">
    	<img src="../../../src/assets/banner.jpg" width="100%"/>
    </div>
		<div class="content">
		  <h2>企业信息确认</h2>
		  <el-form ref="form" :model="form" :rules="rules" label-width="120px">
		    <el-form-item label="企业名称" prop="company">
		      <el-input v-model="form.company"></el-input>
		    </el-form-item>
				<el-form-item label="统一信用代码" prop="uscc">
				  <el-input v-model="form.uscc"></el-input>
				</el-form-item>
				<el-form-item label="联系人" prop="person">
				  <el-input v-model="form.person"></el-input>
				</el-form-item>
				<el-form-item label="联系人职务">
				  <el-input v-model="form.job"></el-input>
				</el-form-item>
				<el-form-item label="联系电话" prop="mobile">
				  <el-input v-model="form.mobile"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSubmit">提交评测申请</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
export default {
  data(){
    return {
			form: {
				company: '',
        uscc: '',
        person: '',
        job: '',
        mobile: '',
			},
      rules: {
        company: [
          { required: true, message: '请填写企业名称', trigger: 'blur' }
        ],
        uscc: [
          { required: true, message: '请填写企业统一信用代码', trigger: 'blur' }
        ],
        person: [
          { required: true, message: '请填写联系人', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请填写联系方式', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '手机号格式有误', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    console.log("mobile", localStorage.getItem('mobile'));
    this.form.mobile = localStorage.getItem('mobile');
    if (this.form.mobile) {
      this.getUserInfo(this.form.mobile);
    } else {
      this.$router.push('/login');
    }
    this.getFormData(this.form.mobile);
  },
  methods: {
		getUserInfo(mobile) {
		  this.$axios.get('/http/api/wanlshop/user/getUser', {
		    params: {mobile: mobile},
		  }).then(res => {
				this.form.person = res.data.data.nickname;
        this.form.company = res.data.data.company_name;
        this.form.uscc = res.data.data.uscc;
        this.form.mobile = res.data.data.mobile;
		  }).catch((err) => {
		    console.log(err)
		  })
		},
    getFormData(mobile){
      this.$axios.post('/zhenduan/api/yexam/Offline/formData',{mobile:mobile}).then((res)=>{
        if(res.data.code == 1){
          this.form = res.data.data;
        }
      })
    },
    onSubmit(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$axios.post('/zhenduan/api/yexam/Offline/add', this.form).then(res => {
            if(res.data.code === 1) {
              this.$message.success(res.data.msg);
              this.$nextTick(() => {
                this.$router.push('/zhenduan')
              })
            }else{
              this.$message.error(res.data.msg);
            }
          })
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
	.downline{
		.banner {
		  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 添加阴影 */
		  margin-bottom: 40px;
		}
		.content{
			width: 1200px;margin: 0 auto;padding-bottom: 60px;
			.el-form{
				width: 800px;margin: 0 auto;
				transform: translateX(-60px);
			}
			.el-button {
			  transition: all 0.3s ease;
			  border-radius: 5px;
				font-size: 20px;padding: 15px 40px;
				margin: 0 auto;display: block;
			}
		}

		.content h2 {
		  font-size: 32px;
		  margin: 60px 0 40px;
		  color: #333;text-align: center;font-weight: bold;
		}

		.content p {
		  font-size: 16px;
		  line-height: 1.8;
		  margin: 20px 0;
		  color: #555;
			text-indent: 2em;
		}
	}
	@media only screen and (max-width:768px) {
		.downline{
			.content{
				width: 100%;padding: 0 15px 50px;
				h2{
					font-size: 24px;
				}
				::v-deep .el-form{
					width: 100%;
					transform: translateX(0px);
					.el-form-item:last-child{
						.el-form-item__content{
							margin-left: 0 !important;
						}
						.el-button{
							width: 100%;font-size: 16px;
						}
					}
				}
			}
		}
	}
</style>
