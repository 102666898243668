<template>
  <div class="list">
		<div class="banner">
			<img src="../../../src/assets/banner.jpg" width="100%"/>
		</div>
		<h1 class="title">通知公告</h1>
		<div class="listbox">
			<div v-for="item in newList" :key="item.id" 
			class="item" @click="toDetail(item.id)">
			<h5>{{item.title}}</h5>
			<span>{{ item.createtime | formattedDate }}</span>
			</div>
		</div>
  </div>
</template>

<script>
import detail from "@/views/news/detail.vue";

export default {
  computed: {
    detail() {
      return detail
    }
  },
  data(){
    return{
      newList: []
    }
  },
	filters: {
	  formattedDate(val) {
	    const date = new Date(val * 1000);
	    const year = date.getFullYear();
	    const month = String(date.getMonth() + 1).padStart(2, '0');
	    const day = String(date.getDate()).padStart(2, '0');
	    return `${year}-${month}-${day}`;
	  }
	},
  mounted() {
    this.getNewList();
  },
  methods: {
    getNewList(){
      this.$axios.post('/http/api/wanlshop/article/newsList', {
        "flag": "",
        "id": 144
      }).then(res => {
        this.newList = res.data.data;
      })
    },
    toDetail(id){
      this.$router.push({path:'/newDetail', query:{id: id}});
    }
  }
}
</script>

<style scoped lang="scss">
.list{
	background: #f9f9f9;min-height: 100vh;padding-bottom: 60px;
	.title {
	  font-size: 32px;
		margin: 60px 0 40px;
		color: #333;
		text-align: center;
		font-weight: bold;
	}
	.listbox{
		width: 1200px;margin: 0 auto;
		padding: 30px;
		background: #fff;
		.item{
			cursor: pointer;
			padding: 10px 0;
			border-bottom: 1px solid #eee;
			
			h5{
				font-size: 16px;width: 80%;float: left;
				margin: 0;padding-left: 15px;
				position: relative;
			}
			h5::before{
				content: '';width: 6px;height: 6px;
				background: #999;border-radius: 50%;
				position: absolute;left: 0;top: 8px;
			}
			span{
				display: block;width: 20%;text-align: right;
				float: left;
				color: #999;
			}
		}
		.item::before,.item::after{
			content: '';clear: both;display: table;
		}
	}
}
@media only screen and (max-width:768px) {
	.list{
		.title{
			font-size: 24px;padding: 15px;
			margin: 20px 0;
		}
		.listbox{
			width: 100%;
			.item{
				h5{
					width: 100%;
				}
				span{
					width: 100%;text-align: left;padding-left: 15px;
					margin: 10px 0 0;
				}
			}
		}
	}
}
</style>
