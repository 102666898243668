<template>
  <div class="detail">
		<div class="banner">
			<img src="../../../src/assets/banner.jpg" width="100%"/>
		</div>
		<h1 class="title">{{newData.title}}</h1>
		<div class="contbox">
			<div class="time">更新时间：{{newData.createtime_text}}</div>
			<div class="content" v-html="newData.content"></div>
		</div>
    
  </div>
</template>

<script>
export default {
  data(){
    return{
      newData: {}
    }
  },
  mounted() {
    const id = this.$route.query.id;
    this.getDetail(id);
  },
  methods: {
    getDetail(id){
      this.$axios.post('/http/api/wanlshop/article/newDetail', {
        "id": id
      }).then(res => {
        this.newData = res.data.data;
      })
    }
  }
}
</script>

<style scoped lang="scss">
	.detail{
		background: #f9f9f9;min-height: 100vh;padding-bottom: 60px;
		.title {
		  font-size: 32px;
			margin: 60px 0 40px;
			color: #333;
			text-align: center;
			font-weight: bold;
		}
		.contbox{
			width: 1200px;margin: 0 auto;
			padding: 30px;
			background: #fff;
			.time{
				text-align: center;color: #999;
			}
			.content{
				padding: 30px 0;
				font-size: 16px;line-height: 2;
				img{
					max-width: 100%;
				}
			}
		}
	}
	@media only screen and (max-width:768px) {
		.detail{
			.title{
				font-size: 24px;padding: 15px;
				margin: 20px 0;
			}
			.contbox{
				width: 100%;
				.item{
					
				}
			}
		}
	}
</style>
