<template>
  <div class="home">
    <p>首页</p>
    <el-button type="success" size="small" @click="login">登录</el-button>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  methods: {
    login(){
      this.$router.push('/login')
    }
  },
  components: {

  }
}
</script>
