<template>
	<div class="loginpg">
		<div class="mbbg">
			<img src="../../../public/img/login-tplogo.png" width="100%"/>
			<img src="../../../public/img/login-tpword.png" width="100%"/>
		</div>
		<div class="midbox" v-if="denglu == false">
			<div class="loginbox regbox">
				<div class="logintit">账号注册</div>
				<el-form :model="regForm" :rules="registerRules" ref="registerRule" label-width="90px" class="demo-ruleForm">
					<el-form-item label="企业名称" prop="company">
						<el-input v-model="regForm.company" placeholder="填写企业名称"></el-input>
					</el-form-item>
					<!-- <el-form-item label="统一信用代码" prop="uscc">
						<el-input v-model="regForm.uscc" placeholder="填写企业统一信用代码"></el-input>
					</el-form-item> -->
					<el-form-item label="姓名" prop="name">
						<el-input v-model="regForm.name" placeholder="填写您的姓名"></el-input>
					</el-form-item>
					<el-form-item label="手机号" prop="mobile">
						<el-input v-model="regForm.mobile" placeholder="填写手机号"></el-input>
					</el-form-item>
					<el-row :gutter="15">
						<el-col :span="16">
							<el-form-item label="验证码" prop="code">
								<el-input v-model="regForm.code" autocomplete="off"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="8">
							<el-button type="primary" class="yzmbtn" @click="sendNoteReg()" :disabled="noteDisabled">{{noteMsg}}</el-button>
						</el-col>
					</el-row>
					<div class="xieyi">
						<el-checkbox v-model="checked"></el-checkbox>
						<span @click="xieyiDialog = true">已阅读并同意<a>《聚通云企业数字化帐套协议》</a></span>
					</div>
					<el-button type="submit" class="loginbtn" @click="register()">注册</el-button>
					<div class="gologinword">已有账号？<span @click="lijidenglu">立即登录</span></div>
				</el-form>
			</div>
		</div>
		<div class="midbox" v-else>
			<div class="loginbox">
				<div class="logintit">账号登录</div>
				<el-form :model="loginForm" ref="loginRule" label-width="60px" class="demo-ruleForm">
					<el-form-item label="手机号" prop="captcha">
						<el-input v-model="loginForm.mobile"></el-input>
					</el-form-item>
					<el-row :gutter="15">
						<el-col :span="16">
							<el-form-item label="验证码" prop="captcha">
								<el-input v-model="loginForm.captcha" autocomplete="off"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="8">
							<el-button type="primary" class="yzmbtn" @click="sendNote()" :disabled="noteDisabled">{{ noteMsg }}</el-button>
						</el-col>
					</el-row>
					<el-button type="submit" class="loginbtn" @click="login()">登录</el-button>
					<div class="gologinword">还没有账号？<span @click="lijizhuce">立即注册</span></div>
				</el-form>
			</div>
		</div>
		<div class="mbbgbot">
			<img src="../../../public/img/login-bot.png" width="100%"/>
		</div>
		<el-dialog
		  title="聚通云企业数字化帐套协议"
		  :visible.sync="xieyiDialog"
		  width="50%">
			<p>本协议由山东渤聚通云计算有限公司（以下简称 “聚通云” 或 “我们”）与使用聚通云企业数字化帐套服务的企业（以下简称 “企业用户”）签订。本协议包含我们提供的诊断服务以及隐私保护相关内容，适用于企业微信号开通企业账套的使用场景。</p>
			<h4>一、诊断服务</h4>
			<h4>1.服务内容</h4>
			<p>（1）账套开通服务</p>
			<p>甲方员工将通过企业微信添加乙方为好友。在乙方按照甲方要求填写几项必要信息后，甲方系统将自动为乙方开通数字化账套。</p>
			<p>（2）免费体验服务项目</p>
			<p>数字化线上诊断：甲方将对与企业微信号开通企业账套相关的系统环境、数据配置以及网络连接等方面进行全面且深入的诊断。具体包括仔细检查企业微信号与账套系统的接口连通性，确保数据传输的准确性和完整性，以及全面排查可能影响账套使用的潜在问题，以全力保障企业账套能够平稳、正常地运行。</p>
			<p>数字化看板：甲方为乙方提供数字化看板服务，使乙方能够直观、清晰地实时了解账套相关数据以及业务动态，以便乙方更好地把握自身企业的运营状况。</p>
			<p>数字化转型政策：甲方将向乙方详细介绍相关的数字化转型政策，助力乙方了解行业趋势和政策导向，为乙方企业的未来发展规划提供坚实有力的支持。</p>
			<p>案例分享：甲方为乙方分享数字化转型案例，使乙方能够从实际案例中汲取宝贵的经验和灵感，为乙方自身企业的数字化转型之路提供具有实际参考价值的借鉴。</p>
			<h4>2.服务方式</h4>
			<p>我们将通过远程技术手段和必要的人工干预相结合的方式进行诊断服务。在企业用户授权的前提下，我们的技术团队将获取相关系统信息和数据，以便准确分析问题所在。</p>
			<p>对于诊断过程中发现的问题，我们将及时向企业用户反馈，并提供详细的解决方案和建议。</p>
			<h4>3.服务期限</h4>
			<p>诊断服务自企业用户提交开通企业账套申请并授权我们进行诊断之日起开始，至我们确认企业账套系统无重大问题且能够正常稳定运行为止。在此期间，我们将持续关注系统运行情况，并根据需要提供进一步的诊断和支持服务。</p>
			<h4>二、隐私保护</h4>
			<h4>1.信息收集与使用</h4>
			<p>在提供诊断服务和企业账套运营过程中，我们可能会收集企业用户及其员工的相关信息，包括但不限于企业基本信息（如企业名称、企业规模、行业类型等）、企业微信号相关信息（如账号 ID、授权范围等）、员工个人信息（如姓名、职位、联系方式等）以及与账套使用相关的数据信息（如操作记录、业务数据等）。</p>
			<p>我们收集这些信息的目的是为了更好地提供诊断服务、确保企业账套的正常运行以及优化我们的服务质量。我们将严格按照法律法规要求，在获得企业用户和员工的明确授权同意后，合理使用这些信息，且不会将其用于任何与本协议约定服务无关的其他目的。</p>
			<h4>2.信息共享与披露</h4>
			<p>我们不会与任何第三方公司、组织或个人共享企业用户及其员工的个人信息，但以下情况除外：</p>
			<p>在获得企业用户明确同意的情况下，我们可能会与特定的第三方合作伙伴共享信息，这些合作伙伴将严格按照我们的要求，在授权范围内使用信息，且不会将其用于其他任何超出授权范围的目的。</p>
			<p>根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，我们可能会对外共享相关信息。</p>
			<p>我们仅会在以下情况下公开披露企业用户及其员工的个人信息：</p>
			<p>获得企业用户明确同意后；</p>
			<p>基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露相关信息。</p>
			<h4>3.信息存储与保护</h4>
			<p>我们将在中华人民共和国境内存储企业用户及其员工的个人信息。我们会采取各种符合业界标准的物理、电子和管理方面的安全措施来保护这些信息，防止其遭到未经授权访问、公开披露、使用、修改、损坏或丢失。</p>
			<p>我们只会在达成本协议所述目的所需的期限内保留个人信息，除非法律有强制的留存要求。在超出保留期间后，我们会根据适用法律的要求删除或匿名化处理这些信息。</p>
			<h4>三、其他条款</h4>
			<p>为了给企业用户提供更好的服务，我们的业务可能会不时变化，本协议也将随之调整。我们会通过在我们官网上发出更新版本或以其他方式提醒企业用户相关内容的更新。若企业用户继续使用我们的服务，即表示同意接受修改后的本协议并受之约束。</p>
			<p>未经企业用户明确同意，我们不会削减企业用户按照本协议所应享有的权利。对于重大变更，我们还会提供更为显著的通知，企业用户可以在聚通云官网随时查看。</p>
			<p>本协议自企业用户确认并同意之日起生效，有效期至企业用户停止使用聚通云企业数字化帐套服务之日止。</p>
			<span slot="footer" class="dialog-footer">
		    <el-button @click="xieyiDialog = false">关闭</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
			denglu: false,
      noteMsg: '发送验证码',
      noteDisabled: false,
			checked: false,
			xieyiDialog: false,
			regForm: {
				company: '',
				//uscc: '', //信用代码
				name: '',
				mobile: '',
				code: '',
			},
      loginForm: {
				mobile: '',
        captcha: ''
      },
      registerRules: {
        company: [
          { required: true, message: '请填写所属企业', trigger: 'blur' }
        ],
        // uscc: [
        //   { required: true, message: '请填写企业统一信用代码', trigger: 'blur' }
        // ],
				name: [
				  { required: true, message: '请填写您的姓名', trigger: 'blur' }
				],
        mobile: [
          { required: true, message: '请填写联系方式', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '手机号格式有误', trigger: 'blur' }
        ]
      }
    };
  },
  mounted() {
    const mobile = localStorage.getItem('mobile');
    if (mobile) {
      this.$router.push('/my');
    }
  },
  methods: {
		lijidenglu(){
			this.denglu = true;
			window.scrollTo(0, 0);
		},
		lijizhuce() {
			this.denglu = false;
			window.scrollTo(0, 0);
		},
    login() {
      this.$axios.post('/http/api/user/mobilelogin', this.loginForm).then(res => {
        if (res.data.code === 1) {
              this.$message.success(res.data.msg);
              // 登录成功后接口返回mobile,作为用户唯一标识,前端存储到localStorage中后期使用，页面跳转到个人中心
              localStorage.setItem('mobile', res.data.data.userinfo.mobile);
              localStorage.setItem('company', res.data.data.userinfo.company_name);
              //localStorage.setItem('uscc', res.data.data.userinfo.uscc);
              this.$router.push('/my');
            } else {
              this.$message.error(res.data.msg);
            }
          }
      ).catch(error => {
        console.log(error.data.msg);
      });
    },
    register(){
			if(this.checked == false){
				this.$message({
					message: '请先勾选“已阅读并同意《聚通云企业数字化帐套协议》”',
					type: 'warning'
				});
			}else{
				this.$refs.registerRule.validate((valid) => {
				  if (valid) {
				    this.$axios.post('/http/api/user/register', this.regForm).then(res=> {
				      if(res.data.code === 1){
				        this.$message.success(res.data.msg);
				        this.denglu = true;
				        this.noteDisabled = false;
				      }else{
				        this.$message.error(res.data.msg);
				      }
				    })
				  }
				})
			}
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 发送短信（登录）
    sendNote(){
      this.$axios.post('/http/api/sms/send', {
        mobile: this.loginForm.mobile,
        event: 'mobilelogin',
      }).then(res=> {
        if(res.data.code === 1){
          this.$message.success(res.data.msg);
          this.noteMsg = '已发送';
          this.noteDisabled = true;
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    // 发送短信（注册）
    sendNoteReg(){
      this.$axios.post('/http/api/sms/send', {
        mobile: this.regForm.mobile,
        event: 'register',
      }).then(res=> {
        if(res.data.code === 1){
          this.$message.success(res.data.msg);
          this.noteMsg = '已发送';
          this.noteDisabled = true;
        }else{
          this.$message.error(res.data.msg);
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.loginpg{
	height: 100vh;
	.xieyi{
		margin: -20px 0 10px;clear: both;
		span{
			color: #fff;margin-left: 10px;
			a{
				cursor: pointer;color: #409EFF;
			}
		}
		
	}
	::v-deep .el-dialog{
		.el-dialog__body{
			height: 500px;overflow: auto;
			padding: 10px 20px;
			h4{
				font-weight: bold;
			}
			p{
				text-indent: 2em;
			}
		}
	}
}
</style>
