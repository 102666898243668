import { render, staticRenderFns } from "./companyZD.vue?vue&type=template&id=12aba43e&scoped=true"
import script from "./companyZD.vue?vue&type=script&lang=js"
export * from "./companyZD.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12aba43e",
  null
  
)

export default component.exports