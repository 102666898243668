<template>
  <div class="online">
    <div class="banner">
      <img src="../../../src/assets/banner.jpg" width="100%"/>
    </div>
    <h1 class="title">生产性服务业中小企业数字化水平评测表（2024年版）</h1>
    <div class="quiz-container">
      <!-- 阶段进度条 -->
      <el-steps :active="activeStep" align-center finish-status="success">
        <el-step v-for="step in steps" :key="step" :title="step"></el-step>
      </el-steps>

      <!-- 题目展示区 -->
      <div class="quiz-content" v-if="activeStep < steps.length - 1">
        <div v-for="(question, index) in currentQuestions" :key="index" class="question-block">
          <h3>{{ index + 1 }}. {{ question.question_name }}（{{ question.type == '1' ? '单选' : '多选' }}）</h3>
          <div v-if="question.type == '1'">
            <el-radio-group v-model="question.answer">
              <el-radio v-for="option in question.options" :key="option.value" :label="option.value">
                {{ option.text }}
              </el-radio>
            </el-radio-group>
          </div>
          <div v-if="question.type == '2'">
            <el-checkbox-group v-model="question.answer">
              <el-checkbox v-for="option in question.options" :key="option.value" :label="option.value">
                {{ option.text }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>

      <!-- Table Phase -->
      <div v-else>
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="secondIndex" label="二级指标(业务场景)" width="180"></el-table-column>
          <el-table-column label="一级">
            <template v-slot="scope">
              <el-radio-group v-model="selected[scope.$index].one" @change="updateStyle(scope.$index, 'one')">
                <el-radio :label="true" :class="getClass(scope.$index, 'one')"
                          @click.native.prevent="handleClick(scope.$index, 'one')">
                  <div class="radio-content">{{ scope.row.one }}</div>
                </el-radio>
              </el-radio-group>
            </template>
          </el-table-column>

          <el-table-column label="二级">
            <template v-slot="scope">
              <el-radio-group v-model="selected[scope.$index].two" @change="updateStyle(scope.$index, 'two')">
                <el-radio :label="true" :class="getClass(scope.$index, 'two')"
                          @click.native.prevent="handleClick(scope.$index, 'two')">
                  <div class="radio-content">{{ scope.row.two }}</div>
                </el-radio>
              </el-radio-group>
            </template>
          </el-table-column>

          <el-table-column label="三级">
            <template v-slot="scope">
              <el-radio-group v-model="selected[scope.$index].three" @change="updateStyle(scope.$index, 'three')">
                <el-radio :label="true" :class="getClass(scope.$index, 'three')"
                          @click.native.prevent="handleClick(scope.$index, 'three')">
                  <div class="radio-content">{{ scope.row.three }}</div>
                </el-radio>
              </el-radio-group>
            </template>
          </el-table-column>

					<el-table-column label="四级">
						<template v-slot="scope">
							<el-radio-group v-model="selected[scope.$index].four" @change="updateStyle(scope.$index, 'four')">
								<el-radio :label="true" :class="getClass(scope.$index, 'four')" @click.native.prevent="handleClick(scope.$index, 'four')">
									<div class="radio-content">{{ scope.row.four }}</div>
								</el-radio>
							</el-radio-group>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>

    <!-- 按钮区 -->
    <div class="buttons">
      <el-button @click="prevStep" :disabled="activeStep === 0">上一步</el-button>
      <el-button v-if="activeStep < steps.length - 1" type="primary" @click="nextStep">下一步</el-button>
      <el-button v-else type="primary" @click="dialogVisible = true">提交</el-button>
    </div>

    <el-dialog
        title="提示"
        custom-class="mydialog"
        :visible.sync="dialogVisible"
				 v-loading="loading"
        width="500">
      本企业根据自身实际情况自主、自愿填报中小企业数字化水平评测表，现郑重承诺如下:
      以上所填内容和提交资料均准确、真实、合法、有效、无涉密信息，本企业为此承担有
      关法律责任。
      <el-checkbox v-model="agree"  @click="agree = !agree">
        已确认阅读并遵守以上规定。
      </el-checkbox>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="submitAnswers">确定</el-button>
          <el-button @click="dialogVisible = false">
            取消
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeStep: 0, // 当前步骤
      steps: ['数字化基础', '数字化管理', '数字化成效', '业务场景'], // 阶段名称
      allQuestions: [],
      answers: {}, // 用户的回答
      tableData: [
        {
          secondIndex: "产品设计*",
          one: "通过设计软件工具（如 CAD、CAE、EDA 等）辅助开展产品设计。",
          two: "应用信息化系统开展产品设计，实现产品设计过程或版本的数字化、规范化管理，形成完整的产品设计资料（如方案、图纸、模型、设计 BOM、版本、技术变更等）管理标准，并有效执行。",
          three: "建立典型产品组件及关键零部件的标准库及典型产品设计知识库，并能在产品设计时进行匹配、引用或参考，实现产品设计与工艺设计的协同，实现数据跨部门共享。",
          four: "运用仿真分析等技术实现对产品外观、结构、性能等进行试验验证或迭代优化等功能，并实现产业链上下游间的多方信息交互、协同设计或产品创新。",
          isImportant: true,
        },
        {
          secondIndex: "工艺设计",
          one: "应用设计软件工具（如CAM、CAPP 等）基于产品设计数据辅助开展工艺设计。",
          two: "应用信息化系统开展工艺设计，并实现设计过程的数字化、规范化管理，形成完整的工艺设计数据（工艺方案、工艺流程、工艺文件、制造 BOM、版本、技术变更等）管理标准，并有效执行。",
          three: "建立典型制造工艺流程、参数、资源等关键要素的知识库，并能在新产品工艺设计时进行匹配、引用或参考；实现工艺设计与生产系统间的数据交互、并行协同。",
          four: "建立数据模型，基于质量、成本等数据运用三维仿真等技术实现对于工艺设计的模拟仿真、迭代优化。",
          isImportant: false,
        },
        {
          secondIndex: "营销管理*",
          one: "借助信息技术工具（如电子表格、云存储等）对销售信息（如销售计划、销售订单、销售运行、客户信息或销售业绩等）进行辅助记录和管理。",
          two: "使用信息化系统对营销信息（如销售计划、销售订单、销售运行、客户信息或销售业绩等）进行规范化管理。",
          three: "基于销售信息化系统实现对营销信息（如销售计划、销售订单、销售运行、客户信息或销售业绩等）的实时管控，实现销售信息化系统与生产、库存、财务等系统的数字化协同。",
          four: "使用人工智能等前沿技术，实现销售、财务、生产、供应链之间的数字化协同，实现实时销售预测，并自动或半自动制定采购、生产、物流等计划或方案，不断提升柔性化制造水平。",
          isImportant: true,
        },

        {
          secondIndex: "售后服务",
          one: "运用信息技术工具（如小程序、APP 等）对售后服务流程进行辅助管理。",
          two: "运用信息化系统实现售后服务流程的数字化、规范化管理，并与设计、工艺、生产、销售部门进行信息共享。",
          three: "建立售后问题清单，实现售后问题的快速响应，并能够指导产品设计、工艺优化，实现售后服务与财务、质量等的系统的数字化协同（如供应商索赔、本厂质量考核账务处理等）。",
          four: "基于信息系统实现面向客户的精细化管理（如远程运维、主动式客户服务等内容）；或建立客户服务数据模型，实现满足客户需求的精准服务。",
          isImportant: false,
        },
        {
          secondIndex: "计划排程",
          one: "应用信息技术工具（如电子表格、云存储等）辅助人工编制生产计划。",
          two: "应用信息化系统辅助生成生产计划，基于生产计划进行生产准备检查（如物料、设备等），实现规范化管理。",
          three: "应用信息化系统实现基于物料安全库存、销售订单、采购提前期、生产交期等多约束条件自动生成生产计划，并实现生产计划的下达与执行。",
          four: "运用人工智能等前沿技术，建立生产排产与调度算法模型，实现自动给出满足多种约束条件的优化排产方案，形成优化的详细生产作业计划，生产情况实时监测，提前处理生产过程中的波动和风险，实现动态实时的生产排产和调度。",
          isImportant: false,
        },
        {
          secondIndex: "生产管控*",
          one: "应用信息技术工具（如电子表格、云存储等）辅助人工进行生产工单数据的记录。",
          two: "应用信息化系统实现生产工单信息（如生产工单进度、产量、物料领用/耗用等）录入、跟踪，实现规范化管理。",
          three: "应用信息化系统对生产工单信息、工艺参数进行数据采集，实现对生产过程中工单、物料、设备等的管控，实现信息化系统与其他系统（如生产计划、质量或设备等）的协同，实现数据共享。",
          four: "运用人工智能等前沿技术建立生产运行监测预警算法模型，实现对生产过程中工艺参数、设备状态、生产过程等生产作业数据的在线分析与实时监测预警，并驱动生产过程的迭代优化与闭环管控，不断优化生产管理。",
          isImportant: true,
        },
        {
          secondIndex: "质量管理*",
          one: "应用信息技术工具（如电子表格、云存储等）辅助开展产品质量信息的管理。",
          two: "实现生产过程质量数据的数字化采集录入、统计与管理，基于信息化系统实现质量管理流程的规范化管理。",
          three: "应用数字化检测设备及信息化系统实现关键工序质量检测，自动对检测结果判断和报警；或应用信息化系统实现对原材料、半成品、成品质量可追溯。",
          four: "应用前沿技术（如视觉质检）开展产品质量检测，提升检测效率和检测水平，开展产业链上下游质量数据跨企业共享；或构建产品质量管理模型，实现产品质量影响因素识别及缺陷预测性分析。",
          isImportant: true,
        },
        {
          secondIndex: "设备管理*",
          one: "通过人工或手持仪器开展设备点巡检，并应用信息技术工具辅助制定设备管理台账。",
          two: "通过信息技术手段制定设备维护计划，开展设备点巡检、维护保养等功能，实现设备的规范化管理。",
          three: "基于信息化系统实现设备关键运行参数数据的实时采集、故障分析和远程诊断，并依据设备关键运行参数等，实现设备综合效率（OEE）统计。",
          four: "建立设备运行模型和设备故障知识库，实现设备故障自动预警及自动制定预测维护解决方案，并基于设备综合效率的分析等驱动工艺优化和生产作业计划优化",
          isImportant: true,
        },
        {
          secondIndex: "安全生产*",
          one: "应用信息技术工具辅助开展车间安全生产规范的制定及管理。",
          two: "应用信息技术手段实现安全作业规范化管理，开展安全风险数据、重大危险源等在线监测。",
          three: "实现危险废物存储、运输的全流程信息化管理，实现安全生产风险实时报警，建立安全应急预案，实现安全事故处理与相关部门及时协同。",
          four: "基于安全作业、风险管控等数据的分析及建模，实现危险源的预防性管理、自动预警及响应处理。",
          isImportant: true,
        },
        {
          secondIndex: "能耗管理*",
          one: "应用信息技术工具（如电子表格、云存储等）辅助人工进行能耗数据记录。",
          two: "应用信息化系统收集和管理水、电、气、液等能耗数据，实现基于能耗数据的统计分析，实现规范化管理。",
          three: "应用信息化系统或平台，实时采集和管理水、电、气、液以及影响设备能耗的关键数据，实现设备能耗的监测分析与相关部门协同管控优化。",
          four: "建立设备能耗监测与优化算法模型，实现设备能耗实时监测、能源转化效率分析、未来能耗预测及能源优化调度等。",
          isImportant: true,
        },
        {
          secondIndex: "采购管理*",
          one: "借助信息技术工具（如电子表格、云存储等），辅助记录采购订单信息和采购过程信息。",
          two: "应用信息化系统对采购管理信息（如采购需求、采购订单、采购过程或供应商等）进行规范化管理。",
          three: "实现供应商管理、询报比价、采购计划、采购执行的全过程管理，实现应用采购信息化系统与生产、仓储、财务等信息化系统的数字化协同。",
          four: "运用人工智能等前沿技术，实现采购与内外部供应链之间的数字化协同，并实现供应链风险预警预测，动态优化采购策略和方案。",
          isImportant: true,
        },
        {
          secondIndex: "仓储物流*",
          one: "使用信息技术工具（如电子表格、云存储等）辅助记录出入库信息，实现对库存数据的采集管理。",
          two: "使用信息化系统，对物料、成品、半成品、耗材等出入库、库存等数据信息进行统计，实现规范化管理。",
          three: "实现仓储管理信息化系统与生产、采购、财务等信息化系统的数字化协同。",
          four: "使用人工智能等前沿技术，实现仓储物流与供应商库存或客户生产计划间的数字化协同，并能够自动实现物流计划的自动制定实施或厂内物料自动配送；或按照产供销状况，实现智能仓储（如智能预测库存需求，自动调整库存补货策略等）及厂外智能物流（物流监测与策略优化）。",
          isImportant: true,
        },
        {
          secondIndex: "财务管理*",
          one: "使用信息化系统辅助实现日常财务记录，基本的总账管理和财务报表生成（如资产负债表、利润表、现金流量表）。",
          two: "使用信息化系统，实现总账、往来、存货、固定资产、出纳等与财务会计核算的协同，对财务实现规范化管理。",
          three: "实现业务数据与财务管理的协同，能支持企业的管理会计核算，实现通过财务的分析辅助决策，帮助企业快速掌握资产、负债、收入、成本、盈利能力等变动和使用情况，实现资产的优化配置和利用。",
          four: "实现企业内外部协同，实现企业财务管理全面智能化和数据驱动，并实现对企业未来的财务状况进行预测、规划和风险评估。",
          isImportant: true,
        },
        {
          secondIndex: "人力资源",
          one: "采用信息技术工具（如电子表格、云存储等），辅助实现员工、流程的信息记录。",
          two: "基于信息化系统实现对考勤和薪酬福利等核心流程的规范化管理。",
          three: "利用人力资源数据分析工具进行关键指标分析，数据驱动人力资源战略规划和决策制定。",
          four: "应用人工智能等前沿技术，实现个性化绩效管理、智能招聘与人才画像、个性化的培训和发展计划，支持战略性人才管理。",
          isImportant: false,
        },
        {
          secondIndex: "协同办公",
          one: "应用信息技术工具（如电子邮件或文档共享服务等通讯工具）辅助日常沟通和简单的信息共享文档处理。",
          two: "部署具有更丰富功能的协同平台或办公软件，实现日常业务（如请假、报销、审批、通知、公告或新闻等）流程的数字化。",
          three: "应用协同平台实现与财务、采购、生产、项目管理等专业业务管理系统集成，实现数据共享和业务流程的无缝对接，且利用移动工具，提升跨部门协作效率和响应速度。",
          four: "应用人工智能等前沿技术实现内部、外部数据的协同，在自动问答、智能推荐、智能预测分析和自适应工作流程等办公场景，组织员工在高度互联和智能化环境中实现无缝协作办公。",
          isImportant: false,
        },
        {
          secondIndex: "决策支持",
          one: "运用信息技术工具辅助收集企业生产经营过程基本数据，为管理者提供简单的决策建议或方向。",
          two: "运用信息化系统，整合关键业务环节的数据，使用业务关联分析和决策支持工具，提供直观的可视化数据。",
          three: "利用数据驱动平台针对特定业务场景（如工艺设计、报价策略、生产计划、变更管理等）实施数据模拟与效能优化，助力决策者精准评估并采纳最佳实践方案。",
          four: "运用人工智能等前沿技术整合企业内外部数据，构建智能化的预测、预警和决策模型，辅助管理层或业务人员进行智能化流程决策，挖掘数据背后的深层次规律和价值。",
          isImportant: false,
        },
        // 更多数据...
      ],
      selected: [
        {secondIndex: "产品设计*", one: false, two: false, three: false, four: false, isImportant: true},
        {secondIndex: "工艺设计", one: false, two: false, three: false, four: false, isImportant: false},
        {secondIndex: "营销管理*", one: false, two: false, three: false, four: false, isImportant: true},
        {secondIndex: "售后服务", one: false, two: false, three: false, four: false, isImportant: false},
        {secondIndex: "计划排程", one: false, two: false, three: false, four: false, isImportant: false},
        {secondIndex: "生产管控*", one: false, two: false, three: false, four: false, isImportant: true},
        {secondIndex: "质量管理*", one: false, two: false, three: false, four: false, isImportant: true},
        {secondIndex: "设备管理*", one: false, two: false, three: false, four: false, isImportant: true},
        {secondIndex: "安全生产*", one: false, two: false, three: false, four: false, isImportant: true},
        {secondIndex: "能耗管理*", one: false, two: false, three: false, four: false, isImportant: true},
        {secondIndex: "采购管理*", one: false, two: false, three: false, four: false, isImportant: true},
        {secondIndex: "仓储物流*", one: false, two: false, three: false, four: false, isImportant: true},
        {secondIndex: "财务管理*", one: false, two: false, three: false, four: false, isImportant: true},
        {secondIndex: "人力资源", one: false, two: false, three: false, four: false, isImportant: false},
        {secondIndex: "协同办公", one: false, two: false, three: false, four: false, isImportant: false},
        {secondIndex: "决策支持", one: false, two: false, three: false, four: false, isImportant: false},
      ],
      dialogVisible: false,
			loading: false,
      agree: false,

    };
  },
  computed: {
    currentQuestions() {
      if (this.allQuestions.length > 0) {
        if (this.activeStep < this.steps.length - 1) {
          // 根据阶段动态获取对应阶段的题目
          const unitName = this.steps[this.activeStep];
          const stepQuestions = this.allQuestions.filter(question => question.unit_name === unitName);
          // console.log(stepQuestions)
          return stepQuestions;
        }
        return [];
      }
    },
  },

  methods: {
    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep--;
      }
      window.scrollTo(0, 0);
    },
    nextStep() {
      let unansweredQuestions = [];
      this.currentQuestions.forEach((question) => {
        if (question.type === '1') {
          // 单选题未回答
          if (!question.answer) {
            unansweredQuestions.push({
              questionId: question.id,
              questionName: question.question_name,
              missingAnswer: "单选题"
            });
          }
        } else if (question.type === '2') {
          // 多选题未回答
          if (!question.answer || question.answer.length === 0) {
            unansweredQuestions.push({
              questionId: question.id,
              questionName: question.question_name,
              missingAnswer: "多选题"
            });
          } else {
            // 检查是否选择了“无”选项
            const hasNoneOption = question.answer.includes("0"); // 假设“无”的 value 是 "0"
            if (hasNoneOption && question.answer.length > 1) {
              unansweredQuestions.push({
                questionId: question.id,
                questionName: question.question_name,
                missingAnswer: "选择“无”后，不可再选择其他选项"
              });
            }
          }
        }
      });
      if (unansweredQuestions.length > 0) {
        // this.$message.error('请将本页选项选完再进行下一页');
        let msg = "";
        unansweredQuestions.forEach((q, index) => {
          msg += `请核对题目-${q.questionName} (${q.missingAnswer});`+' ';
        });
        this.$message.error(msg);
        return false;
      }else{
        if (this.activeStep < this.steps.length - 1) {
          this.activeStep++;
        }
        window.scrollTo(0, 0);
      }
    },
    submitAnswers() {
      if(this.agree == true){
				this.loading = true;
        this.$axios.post('/zhenduan/api/yexam/exam/addExamResult', {
          mobile: localStorage.getItem('mobile'),
          answersList: this.allQuestions,
          selectedList: this.selected
        }).then(res => {
          if (res.data.code == 1) {
            this.$message.success('答案提交成功！');
						this.loading = false;
            this.$router.push({
              path: '/success',
              query: {}
            });
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch((err) => {
          console.log(err)
          this.$message.error('出现错误');
        })
      }else{
        this.$message.error('还未勾选“已确认阅读并遵守以上规定”！');
      }
    },
    updateStyle(index, column) {
      for (const key in this.selected[index]) {
        if (key !== column && key != "secondIndex" && key != "isImportant") {

          this.selected[index][key] = false;
        }
      }
    },
    getClass(row, column) {
      return this.selected[row][column] ? "selected-radio" : "default-radio";
    },
    handleClick(row, column) {
      console.log(this.selected[row][column])
      if (this.selected[row][column]) {
        this.selected[row][column] = false;
      } else {
        this.selected[row][column] = true;
      }
      this.updateStyle(row, column);
    },
    async fetchQuestions() {
      this.$axios.post('/zhenduan/api/yexam/exam/examQuestionsBySubject', {
        subject_id: 3,
        mobile: localStorage.getItem('mobile')
      }).then(res => {
        this.allQuestions = res.data.data.questions;
        this.steps = res.data.data.steps;

      }).catch((err) => {
        console.log(err)
      })
    },
    async getChangjing() {
      this.$axios.post('/zhenduan/api/yexam/exam/getChangjing', {mobile: localStorage.getItem('mobile')}).then(res => {
        if (res.data.data.length > 0) {
          // console.log(res.data.data)
          this.selected = res.data.data;
        }

      }).catch((err) => {
        console.log(err)
      })
    },
  },
  mounted() {
    this.fetchQuestions(); // 组件加载时从后台获取题目
    this.getChangjing()
  },
};
</script>

<style scoped lang="scss">
.online {
  background: #f9f9f9;
  padding-bottom: 50px;
}

.quiz-container {
  width: 1200px;
  margin: 50px auto;
  background: #fff;
  padding: 30px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  ::v-deep .el-step {
    .el-step__title {
      text-align: center;
    }

    .el-step__title.is-process {
      color: #409eff;
    }

    .el-step__head.is-process {
      color: #409eff;
      border-color: #409eff;
    }

    .el-step__head.is-success {
      color: #409eff;
      border-color: #409eff;
      font-weight: bold;
    }

    .el-step__title.is-success {
      color: #409eff;
      font-weight: bold;
    }
  }

  .el-checkbox {
    border: 0;
    margin: 0;
  }

  .el-radio {
    border: 0;
    justify-content: initial;
  }

  ::v-deep .el-table {
    margin: 50px 0 0;

    thead th .cell {
      text-align: center;
      font-size: 16px;
      color: #333;
    }

    .el-table__cell {
      background: #fff !important;
    }

    tr td:first-child {
      font-size: 16px;
      font-weight: bold;
      color: #333;
      text-align: center;
    }

    .radio-content {
      text-align: left;
      line-height: 1.5;
      font-size: 14px;
    }
  }
}

.number {
  text-align: center;

  span {
    margin: 0 15px;
  }
}

.title {
  font-size: 32px;
  margin: 60px 0 40px;
  color: #333;
  text-align: center;
  font-weight: bold;
}

.quiz-content {
  margin: 30px 0;
}

.question-block {
  padding: 20px 0;
  border-bottom: 1px solid #eee;

  h3 {
    margin: 0 0 10px;
    font-size: 18px;
    font-weight: bold;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;

  .el-button {
    border-radius: 5px;
    font-size: 20px;
    padding: 15px 40px;
  }
}

.el-radio {
  cursor: pointer;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 8px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-checkbox {

  cursor: pointer;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 8px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
}

/* 隐藏 el-radio 默认的圆形选中按钮 */
.el-radio__input {
  display: none;
}

/* 调整 el-radio 的内容样式，使其可以多行显示 */
.radio-content {
  white-space: normal;
  text-align: center;
  word-break: break-word;
  padding: 5px 10px;
}

/* 默认选项样式 */
.default-radio {
  background-color: #f5f7fa;
  color: #606266;
}

/* 悬停样式 */
.default-radio:hover {
  background-color: #f0f9ff;
  border-color: #409eff;
}

/* 选中时的样式 */
.selected-radio {
  background-color: #409eff;
  color: #ffffff;
  border-color: #409eff;
}

.selected-radio .radio-content {
  color: #ffffff !important; /* 内部内容区域的字体颜色 */
}

/* 点击时的样式 */
.selected-radio:active {
  background-color: #66b1ff;
}
::v-deep .mydialog{
	.el-checkbox{
		border: 0;margin-top: 20px
	}
}
	@media only screen and (max-width:768px) {
		.online{
			.title{
				font-size: 24px;padding: 15px;
			}
			.quiz-container{
				width: 100%;padding: 30px 15px 50px;
				::v-deep .el-checkbox{
					.el-checkbox__label{
						white-space: initial;
					}
				}
				::v-deep .el-radio__label{
					white-space: initial;line-height: 1.5;
				}
				::v-deep .el-step{
					.el-step__title{
						font-size: 14px;
					}
				}
				.question-block h3{
					font-size: 16px;line-height: 1.5
				}
			}
			.buttons{
				width: 100%;padding: 0 15px;
				.el-button{
					font-size: 16px;padding: 10px 20px;
				}
			}
			::v-deep .el-table{
				border: 0;
				.el-table__body{
					width: 100% !important;display: block;
				}
				.el-table__cell{
					display: block;border: 0;padding: 4px 0;
				}
				tr:first-child{
					display: none;
				}
				tbody{
					width: 100%;display: block;
				}
			}
			::v-deep .el-table--border::after{
				display: none;
			}
			::v-deep .el-dialog{
				width: 90%;
			}
		}
	}
</style>
