import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from '@/components/login'
import My from '@/views/my.vue'
import CompanyZD from "@/views/companyZD.vue";
import Zhenduan from "@/views/zhenduan/index.vue";
import Online from "@/views/zhenduan/online.vue";
import Success from "@/views/zhenduan/success.vue";
import Downline from "@/views/zhenduan/downline.vue";
import Downlineform from "@/views/zhenduan/downlineform.vue";
import Detail from "@/views/news/detail.vue";
import newList from "@/views/news/list.vue";
import aboutView from "@/views/AboutView.vue";
import Elevate from "@/views/zhenduan/elevate.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/my"
  }, {
    path: '/home',
    name: 'home',
    component: HomeView
  },{
    path: '/login',
    name: 'login',
    component: Login
  },{
    path: '/about',
    name: 'about',
    component: aboutView
  },{
    path: '/my',
    name: 'my',
    component: My
  },{
    path: '/companyZD',
    name: 'companyZD',
    component: CompanyZD
  },{
    path: '/zhenduan',
    name: 'zhenduan',
    component: Zhenduan
  },{
    path: '/online',
    name: 'online',
    component: Online
  },{
    path: '/downline',
    name: 'downline',
    component: Downline
  },{
    path: '/downlineform',
    name: 'downlineform',
    component: Downlineform
  },{
    path: '/success',
    name: 'success',
    component: Success
  },{
    path: '/newList',
    name: 'newList',
    component: newList
  },{
    path: '/newDetail',
    name: 'newDetail',
    component: Detail
  },
  {
    path: '/elevate',
    name: 'elevate',
    component: Elevate
  }
]

const router = new VueRouter({
  mode: '',
  base: process.env.BASE_URL,
  routes,
  // 解决路由跳转新页面不在顶部
  scrollBehavior() {
    return {x: 0, y: 0};
  }
})

export default router
