<template>
  <div class="success">
    <div class="boxcard">
      <div class="cptit">评测结果</div>
      <div class="content">
        <el-row>
          <el-col :span="12">
            <div class="cpitem">
              <h4>企业名称</h4>
              <p>{{ companyName }}</p>
            </div>
          </el-col>
          <!-- <el-col :span="8">
            <div class="cpitem">
              <h4>统一社会信用代码</h4>
              <p>{{ companyCode }}</p>
            </div>
          </el-col> -->
          <el-col :span="12">
            <div class="cpitem">
              <h4>评测时间</h4>
              <p>{{ create_time }}</p>
            </div>
          </el-col>
        </el-row>
        <div class="score-section">
          <h2>{{ score }} ({{ level }})</h2>
          <p>{{ companyName }}数字化水平整体得分为{{ score }}，整体处于数字化水平{{ level }}阶段。</p>
        </div>

        <el-button type="primary" @click="downloadReport">重新测评</el-button>&nbsp;&nbsp;
<!--        <el-button type="primary" @click="toElevate">水平提升</el-button>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      companyName: "",
      companyCode: "",
      level: '无等级', // 阶段名称
      score: 0,
      create_time: '',
    }
  },
  methods: {
    downloadReport() {
      // 跳转到在线测评页面
      this.$router.push({
        path: '/online',
        query: {}
      });
    },
    async getResult() {
      this.$axios.post('/zhenduan/api/yexam/exam/getExamResult', {mobile: localStorage.getItem('mobile')}).then(res => {
        if (res.data.code == 1) {
          // this.companyName = res.data.data.companyName;
          // this.companyCode = res.data.data.companyCode;
          this.level = res.data.data.level;
          this.score = res.data.data.score;
          this.create_time = res.data.data.create_time;
        } else {

          this.$message.error(res.data.msg);
          this.$router.push({
            path: 'zhenduan'
          });
        }
        this.steps = res.data.data.steps;
        this.steps.push()
      }).catch((err) => {
        console.log(err)
      })
    },
    getUserInfo(mobile) {
      this.$axios.get('/http/api/wanlshop/user/getUser', {
        params: {mobile: mobile},
      }).then(res => {
        this.companyName = res.data.data.company_name;
        this.companyCode = res.data.data.uscc;
      }).catch((err) => {
        console.log(err)
      })
    },
    toElevate(){
      this.$router.push({
        path: '/elevate',
        query: {}
      });
    }
  },
  mounted() {
    this.getResult(); // 组件加载时从后台获取题目
    this.getUserInfo(localStorage.getItem('mobile'));
  },
}
</script>
<style scoped lang="scss">
.success {
  height: 100vh;
  background: url("../../../src/assets/successbg.jpg") no-repeat;
  background-size: 100% 100%;
  position: relative;
}

.boxcard {
  width: 60%;
  border-radius: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  padding: 150px 50px 50px;
  box-shadow: 0 0 20px #477dde inset;

  .cptit {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(22, 39, 241, 0.5);
    font-size: 30px;
    color: #fff;
    font-weight: bold;
    padding: 30px 50px 0;
  }

  .cptit::after {
    content: '';
    width: 100%;
    height: 1px;
    border-left: 110px solid transparent;
    border-right: 110px solid transparent;
    border-top: 30px solid rgba(22, 39, 241, 0.5);;
    position: absolute;
    bottom: -30px;
    left: 0;

  }
}

.boxcard::before {
  content: '';
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  border-radius: 15px;
  border: 2px solid #1627f1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.boxcard::after {
  content: '';
  width: calc(100% - 50px);
  height: calc(100% - 50px);
  border-radius: 15px;
  border: 1px solid #345bf0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.header-text {
  font-size: 24px;
  font-weight: bold;
  color: #007bff;
  margin-bottom: 20px;
  text-align: center;
}

.content {
  text-align: center;

  .el-button {
    font-size: 20px;
    padding: 15px 40px;
    cursor: pointer;
  }

  .cpitem {

    h4 {
      margin: 0 0 10px;
      text-align: center;
    }

    p {
      font-weight: bold;
      text-align: center;
    }
  }
}

.score-section {
  margin: 20px 0;
}

.score-section h2 {
  font-size: 40px;
  font-weight: bold;
  color: #457bdd;
  text-align: center;
  margin: 20px 0;
}

.score-section p {
  color: #606266;
  text-align: center;
  line-height: 2;
  padding: 0 30px;
}

.el-button {
  margin-top: 20px;
}

@media only screen and (max-width:768px) {
	.boxcard{
		width: 100%;
	}
	.boxcard .cptit{
		padding: 30px 30px 0;
		font-size: 24px;width: 50%;text-align: center;
	}
	.boxcard .cptit::after{
		border-left: 90px solid transparent;
		border-right: 90px solid transparent;
	}
	.content{
		::v-deep .el-col-8{
			width: 100%;margin: 0 0 10px;
		}
		.score-section p{
			padding: 0;
		}
		.el-button{
			font-size: 16px;padding: 10px 20px;
		}
	}

}
</style>
