<template>
	<div class="downline">
    <div class="banner">
    	<img src="../../../src/assets/banner.jpg" width="100%"/>
    </div>
		<div class="content">
		  <h2>线下测评须知</h2>
		  <p>本报告依据工业和信息化部发布的《中小企业数字化水平评测指标(2022年版)》
			以及团体标准《中小企业数字化水平评测规范》(T/CESA1256-2023),结合了中小企业
			所属行业和业务特点，根据评估师现场考察和现场交流诊断所掌握情况，在综合企业
			线上自评测诊断结果的基础上，对企业数字化水平进行评估分析而形成评测诊断结果，
			在中小企业进行了试验验证，并在部分省市得到了有效应用。
			</p>
			<p>
				本报告的评测诊断结果受限于与企业线下交流、现场考察评估的广度和深度，
				同时企业自评测受企业主观意识、对数字化水平理解等限制。本报告的评测
				诊断结果、评测分析和相关建议仅供参考，不作为企业相关方面的能力、资质、
				资格等方面的证明，不具备担保等效力。
			</p>
			<div class="liucheng">
				<div class="item">
					<div class="num">01</div>
					<h3>申请受理</h3>
					<h5>1.企业发起线下评测申请，平台受理申请并组建评测团队，开展内部政策宣贯与前期培训工作。<br />
					2.与申请企业沟通评测需求，确定评测目的及评测范围，制定评测方案及评测计划。
					</h5>
				</div>
				<div class="item">
					<div class="num">02</div>
					<h3>预评测</h3>
					<h5>1.通过网上搜索、发送资料收集调查表、电话或线上沟通等方式，进行基本资料收集，初步了解申请企业等组织架构、数字化部门指责与角色、数字化现状及需求等。<br />
					2.申请企业登陆 网址 进行线上自评测，摸底基础情况，强化政策认知。
					</h5>
				</div>
				<div class="item">
					<div class="num">03</div>
					<h3>正式评测</h3>
					<h5>1.多次召开评测会议并对照 评测指标对企业对各项资料与提供数据进行多方位研习评测，并对访谈对象的回答和核查及观察情况进行记录。<br />
					2.结合评测诊断情况，出具评测报告。
					</h5>
				</div>
				<div class="item">
					<div class="num">04</div>
					<h3>形成评估报告</h3>
					<h5>1.组织评估师力量采用材料评审和实地验证相结合的方式，对评测结果进行评审认证。<br />
2.对经过评估师认证的评测报告加盖评测单位公章，完成测评报告有效性认证。<br />
3.对评测企业发放加盖评测单位公章的证书，证书自颁发之日起有效期为三年。
					</h5>
				</div>
				<div class="item">
					<div class="num">05</div>
					<h3>改进提升</h3>
					<h5>结合评测结果和弱项短板分析，联合优质数字化服务商，为企业制定针对性数字化水平提升详细规划方案并具体实施。
					</h5>
				</div>
			</div>
			<el-checkbox v-model="agree">我已仔细阅读《线下诊断评测须知》，并同意上述内容。</el-checkbox>
			<el-button type="primary" @click="goform">发起测评申请</el-button>
		</div>
	</div>
</template>

<script>
export default {
  data(){
    return {
			agree: false,
    }
  },
  methods: {
    goform(){
			if(this.agree == true){
				this.$router.push({
				  path: '/downlineform',
				  query:{}
				});
			}else{
				this.$message({
					message: '请先勾选“我已仔细阅读《线下诊断评测须知》，并同意上述内容。”',
					type: 'warning'
				});
			}
			
		}
  }
}
</script>
<style scoped lang="scss">
	.downline{
		.banner {
		  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 添加阴影 */
		  margin-bottom: 40px;
		}
		.content{
			width: 1200px;margin: 0 auto;padding-bottom: 60px;
			table{
				width: 100%;
				
				tr{
					font-size: 16px;
					th{
						padding: 10px;text-align: center;
						font-weight: bold;border: 1px solid #ddd;
					}
					td{
						padding: 10px;text-align: center;
						border: 1px solid #ddd;
					}
				}
			}
			.el-button {
			  transition: all 0.3s ease;
			  border-radius: 5px;
				font-size: 20px;padding: 15px 40px;
				display: block;margin: 0 auto;
			}
			::v-deep .el-checkbox{
				color: #467bdb;margin: 0 0 50px;
				.el-checkbox__label{
					font-size: 16px;
				}
				.el-checkbox__inner{
					width: 18px;height: 18px;border-color: #467bdb;
				}
				.el-checkbox__inner::after{
					width: 5px;height: 12px;left: 5px;top: 0;
				}
			}
			
			.liucheng{
				margin: 50px 0 0;
				.item{
					position: relative;padding-left: 80px;
					.num{
						font-size: 24px;font-weight: bold;
						color: #467bdb;text-align: center;
						width: 50px;height: 50px;line-height: 45px;
						border-radius: 50%;border: 2px solid #467bdb;
						position: absolute;left: 0;top: 0;
					}
					.num::before{
						content: '';width: calc(100% + 16px);height: calc(100% + 16px);
						position: absolute;left: -8px;top: -8px;
						border: 1px solid #ddd;
						border-radius: 50%;background: #fff;
						z-index: -1;
					}
					h3{
						margin: 0 0 20px;
						padding: 5px 0 15px;border-bottom: 1px solid #bcd4ff;
						font-size: 28px;
					}
					h5{
						margin: 0;line-height: 2;font-size: 16px;padding-bottom: 50px;
					}
				}
				.item::before{
					content: '';height: 100%;width: 1px;background: #467bdb;
					position: absolute;left: 25px;top: 0;
					z-index: -1;
				}
				.item:last-child::before{
					display: none;
				}
			}
		}
		
		.content h2 {
		  font-size: 32px;
		  margin: 60px 0 40px;
		  color: #333;text-align: center;font-weight: bold;
		}
		
		.content p {
		  font-size: 16px;
		  line-height: 1.8;
		  margin: 20px 0;
		  color: #555;
			text-indent: 2em;
		}
	}
	@media only screen and (max-width:768px) {
		.downline{
			.content{
				width: 100%;padding: 0 15px 50px;
				h2{
					font-size: 24px;
				}
				p{
					font-size: 14px;
				}
				table{
					tr{
						th,td{
							font-size: 14px;
						}
						th:first-child{
							width: 20%;
						}
					} 
				} 
				.el-button{
					font-size: 16px;padding: 10px 20px;
				}
				.liucheng{
					.item{
						h3{
							font-size: 24px;
						}
						h5{
							font-size: 14px;
						}
					}
				}
				::v-deep .el-checkbox{
					.el-checkbox__label{
						font-size: 14px;
						white-space: initial;vertical-align: top;
					}
					.el-checkbox__inner{
						width: 15px;height: 15px
					}
					.el-checkbox__inner::after{
						width: 4px;height: 10px;left: 4px;
					}
				}
			}
		}
		
		
	}
</style>
  